<template>
    <div class="maxbox">
      <div>
        <div class="top">
          <p>邀请好友</p>
        </div>
        <div class="main">
          <div class="main-content">
            <button @click="goback" class="return">
              <img src="@/assets/images/left-arrow.png" />
            </button>
            <div class="panner">
              <img src="@/assets/images/in-main.png" />
            </div>
            <div class="item">
              <h2>邀请你的朋友一起加入</h2>
              <div class="names">
                <p id="currentAddressDisplay">{{ walletAddress }}</p>
                <button @click="copyText('currentAddressDisplay')">
                  <img src="@/assets/images/fz.png" />
                </button>
              </div>
              <div class="pylink">
                <p id="homePageURL">{{ homePageURL }}</p>
                <button @click="copyText('homePageURL')">
                  <img src="@/assets/images/fz.png" />
                </button>
              </div>
              <button class="share" @click="shareInfo">分享</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import { ethers } from 'ethers';
  
    export default {
      name: 'copy-invite',
      data() {
        return {
          walletAddress: '',
          homePageURL: window.location.origin,
          generatedLink: '',
        };
      },
      methods: {
        goback() {
          this.$router.go(-1);
        },
        async fetchWalletAddress() {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          try {
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            this.walletAddress = await signer.getAddress();
            this.generateReferralLink();
          } catch (err) {
            console.error('获取地址失败:', err);
          }
        },
        copyText(elementId) {
          const text = document.getElementById(elementId).textContent;
          if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
          } else {
            navigator.clipboard.writeText(text).then(() => {
              alert('已复制到剪贴板！');
            }, (err) => {
              console.error('无法复制文本:', err);
              this.fallbackCopyTextToClipboard(text);
            });
          }
        },
        fallbackCopyTextToClipboard(text) {
          var textArea = document.createElement("textarea");
          textArea.value = text;
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
            document.execCommand('copy');
            alert('已复制到剪贴板！');
          } catch (err) {
            console.error('无法复制文本:', err);
            alert('无法复制');
          }
          document.body.removeChild(textArea);
        },
        generateReferralLink() {
          const referralLink = `${this.homePageURL}/?referrer=${this.walletAddress}`;
          this.generatedLink = referralLink;
        },
        shareInfo() {
          const combinedText = ` ${this.generatedLink}`;
          if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(combinedText);
          } else {
            navigator.clipboard.writeText(combinedText).then(() => {
              alert('推荐链接已复制到剪贴板！');
            }, (err) => {
              console.error('无法复制文本:', err);
              this.fallbackCopyTextToClipboard(combinedText);
            });
          }
        },
      },
      mounted() {
        this.fetchWalletAddress();
      },
    };
  </script>
  
  <style scoped>
    @import "@/assets/css/invte.css";
    @import "@/assets/css/style.css";
  
    #currentAddressDisplay,
    #homePageURL {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  
    .names p {
      margin-left: 0;
    }
  
    .main {
      padding-top: 50px;
    }
  
    .main-content button {
      width: 40px;
    }
  
    button.share {
      width: 300px;
    }
  </style>