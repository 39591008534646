<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <div class="topid">
          <div id="walletAddress">{{ shortWalletAddress }}</div>
          <div id="walletBalanceTop">金额: {{ walletBalanceTop }} USDT</div>
          <div id="walletBalanceScore">积分: {{ walletScore }} 分</div>
        </div>
      </div>
      <div class="main">
        <div class="header">
          <p>Welcome</p>
        </div>
        <div class="affivhe">
          <img src="@/assets/images/gg.png" alt="">
          <div id="vipStatus" class="top-left-corner golden-text">{{ memberStatus }}</div>
        </div>
        <div class="banner">
          <div class="item1">
            <div class="swiper mySwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="card1">
                    <img src="@/assets/images/ban-1.png" alt="">
                    <h2>1.66500000</h2>
                    <p>私募互助</p>
                    <b><span>WEXCLUB</span></b>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="card1">
                    <img src="@/assets/images/ban-1.png" alt="">
                    <h2>0.82600000</h2>
                    <p>我的WEX</p>
                    <b><span>互助共赢</span></b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="community">
          <h1>加入社区</h1>
          <p style="color: red;">激活需要100USDT</p><br>
          <button id="reactivateMemberButton" @click="reactivateMember">复投激活</button>&nbsp;
          <div id="walletBalanceCommunity" style="display: inline-block; margin-left: 20px;"></div>
          <img src="@/assets/images/sq.png" alt="">
        </div>
        <div class="set">
          <input type="text" id="referrer-address" placeholder="输入推荐人地址">
          <input type="number" id="amount" placeholder="输入金额（100USDT）">
          <button @click="registerMember">绑定推荐人</button>
        </div>
        <!-- 隐藏的文本框用于备用的复制方法 -->
        <textarea id="clipboardContent" style="position: absolute; top: -9999px; left: -9999px;"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
/* global ethereum */
import Swiper from 'swiper';
import { ethers } from 'ethers';
import { usdtTokenAddress, usdtAbi, contractAddress, contractABI } from '@/js/contract.js';

export default {
  name: 'home',
  data() {
    return {
      walletAddress: '未连接',
      shortWalletAddress: '未连接',
      walletBalanceTop: '0',
      walletScore: 0, // 初始化积分
      memberStatus: '未获取',
      referrerAddressFromURL: ''
    };
  },
  mounted() {
    new Swiper(".mySwiper", {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    });

    this.connectWallet();
    this.getAndDisplayMemberStatus();
    this.extractReferrerFromURL();
  },
  methods: {
    async connectWallet() {
      if (window.ethereum) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          const address = await signer.getAddress();

          const usdtContract = new ethers.Contract(usdtTokenAddress, usdtAbi, provider);
          const balance = await usdtContract.balanceOf(address);
          const balanceInUsdt = ethers.utils.formatUnits(balance, 6);
          const balanceInUsdtInt = Math.floor(balanceInUsdt);

          this.walletAddress = address;
          this.shortWalletAddress = `地址:${address.slice(0, 5)}...${address.slice(-4)}`;
          this.walletBalanceTop = balanceInUsdtInt;
          this.loadWalletScore();
        } catch (error) {
          console.error(error);
          alert('连接钱包失败');
        }
      } else {
        alert('请安装 MetaMask 插件');
      }
    },
    async getAndDisplayMemberStatus() {
      if (!window.ethereum) {
        alert('请安装 MetaMask 插件');
        return;
      }

      try {
        await ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const userAddress = await signer.getAddress();
        const contract = new ethers.Contract(contractAddress, contractABI, provider);
        const status = await contract.getMemberStatus(userAddress);

        this.memberStatus = status;

        if(status === 'VIP') {
          this.walletScore += 100; // 注册成功且为VIP会员则赠送100积分
          this.saveWalletScore();
        }
      } catch (error) {
        console.error('获取会员状态失败:', error);
        alert('获取会员状态失败，请查看控制台获取详细信息。');
      }
    },
    async reactivateMember() {
      if (window.ethereum) {
        try {
          await ethereum.request({ method: 'eth_requestAccounts' });
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const userAddress = await signer.getAddress();

          const paymentAmount = ethers.utils.parseUnits('100', 6);
          const usdtContract = new ethers.Contract(usdtTokenAddress, usdtAbi, signer);

          let allowance = await usdtContract.allowance(userAddress, contractAddress);
          if (!allowance.gte(paymentAmount)) {
            const approveTx = await usdtContract.approve(contractAddress, paymentAmount);
            await approveTx.wait();
          }

          const contract = new ethers.Contract(contractAddress, contractABI, signer);
          const tx = await contract.reactivateMember();
          await tx.wait();

          alert('会员重新激活成功');
          // 取消重新激活会员赠送积分
          // this.walletScore += 2980;
          // this.saveWalletScore();
        } catch (error) {
          console.error('重新激活会员失败:', error);
          alert('重新激活会员失败，请查看控制台获取详细信息。');
        }
      } else {
        alert('请安装 MetaMask 插件');
      }
    },
    copyToClipboard() {
      const textToCopy = `网址: ${window.location.href}\n推荐码: ${this.walletAddress}`;
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(textToCopy).then(() => {
          alert('复制成功');
        }, () => {
          this.fallbackCopyTextToClipboard(textToCopy);
        });
      } else {
        this.fallbackCopyTextToClipboard(textToCopy);
      }
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.getElementById('clipboardContent');
      textArea.value = text;
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        alert('复制成功');
      } catch (err) {
        alert('复制失败');
      }
    },
    async registerMember() {
      const referrerAddress = this.referrerAddressFromURL || document.getElementById('referrer-address').value;
      const amount = '100';

      if (!referrerAddress) {
        alert('请输入推荐人地址');
        return;
      }

      if (window.ethereum) {
        try {
          await ethereum.request({ method: 'eth_requestAccounts' });
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const userAddress = await signer.getAddress();
          const paymentAmount = ethers.utils.parseUnits(amount.toString(), 6);
          const usdtToken = new ethers.Contract(usdtTokenAddress, usdtAbi, signer);

          let allowance = await usdtToken.allowance(userAddress, contractAddress);
          if (!allowance.gte(paymentAmount)) {
            const approveTx = await usdtToken.approve(contractAddress, paymentAmount);
            await approveTx.wait();
          }

          const contract = new ethers.Contract(contractAddress, contractABI, signer);
          const tx = await contract.register(referrerAddress);
          await tx.wait();

          alert('注册成功');
          this.walletScore += 2980; // 注册成功后赠送100积分
          this.saveWalletScore();
        } catch (error) {
          console.error('注册过程中出错:', error);
          alert('注册失败。详情请查看控制台。');
        }
      } else {
        alert('请安装 MetaMask 插件');
      }
    },
    extractReferrerFromURL() {
      const urlParams = new URLSearchParams(window.location.search);
      const referrer = urlParams.get('referrer');
      if (referrer) {
        this.referrerAddressFromURL = referrer;
        document.getElementById('referrer-address').value = referrer;
      }
    },
    loadWalletScore() {
      const savedScore = localStorage.getItem(`walletScore_${this.walletAddress}`);
      if (savedScore !== null) {
        this.walletScore = parseInt(savedScore, 10);
      }
    },
    saveWalletScore() {
      localStorage.setItem(`walletScore_${this.walletAddress}`, this.walletScore);
    }
  },
};
</script>

<style scoped>
@import "@/assets/css/swiper-bundle.min.css";
@import "@/assets/css/index.css";
@import "@/assets/css/style.css";
.golden-text {
  color: gold;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
#walletAddress, #walletBalanceTop {
  color: rgb(255, 102, 0);
}
#reactivateMemberButton {
  width: fit-content; /* 设置为自动适应内容宽度 */
  padding: 5px 30px; /* 增加一些内边距，让按钮看起来更大 */
}
#activationText {
  display: inline-block; /* 使其成为行内块元素 */
}
</style> 


