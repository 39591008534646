<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <p>我的资产</p>
      </div>
      <div class="main">
        <button @click="goback" class="return"><img src="@/assets/images/left-arrow.png" alt="返回"></button>
        <div class="main-content">
          <p id="totalRewards">奖金总额：{{ totalRewards }} USDT</p>
          <p id="staticBonus">静态奖金：{{ staticBonus }} USDT</p>
          <p id="dynamicBonus">动态奖金：{{ dynamicBonus }} USDT</p>
          <p id="withdrawableDividends">可提现余额：{{ withdrawableDividends }} USDT</p>
          <input type="number" v-model.number="amountToWithdraw" placeholder="输入提现金额">
          <button @click="withdrawDividends" class="withdraw-button">提现</button><br>
          <button @click="claimFC" class="claim-fc-button">领取FC币</button><br>
          <h3>奖金结算分红明细</h3>
          <div class="dividends-scrollable">
            <ul>
              <li v-for="(dividend, index) in pagedDividends" :key="index">
                结算日期: {{ formatTimestamp(index) }} 金额: {{ formatUSDT(dividend) }} USDT
              </li>
            </ul>
            <button v-if="hasMoreDividends" @click="loadMore" class="load-more-button">加载更多</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddress, contractABI } from '@/js/contract.js';

export default {
  name: 'income',
  data() {
    return {
      totalRewards: '加载中...',
      staticBonus: '加载中...',
      dynamicBonus: '加载中...',
      withdrawableDividends: '加载中...',
      amountToWithdraw: 0,
      dividendsPerRound: [],
      currentPage: 1,
      itemsPerPage: 10 // 每页显示的条目数
    };
  },
  computed: {
    reverseDividendsPerRound() {
      return [...this.dividendsPerRound].reverse();
    },
    filteredDividendsPerRound() {
      return this.reverseDividendsPerRound.filter(dividend => parseFloat(this.formatUSDT(dividend)) > 0);
    },
    pagedDividends() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      return this.filteredDividendsPerRound.slice(start, end);
    },
    hasMoreDividends() {
      return this.currentPage * this.itemsPerPage < this.filteredDividendsPerRound.length;
    }
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    async connectWallet() {
      if (!window.ethereum) {
        console.error("未检测到Ethereum提供商。请安装MetaMask。");
        return null;
      }
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        return new ethers.providers.Web3Provider(window.ethereum);
      } catch (error) {
        console.error("用户拒绝了账户访问请求", error);
        return null;
      }
    },
    async getUserBonuses() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      try {
        const [totalBonuses, withdrawableAmount, [cumulativeStaticBonus, cumulativeDynamicBonus]] = await Promise.all([
          contract.getTotalBonus(),
          contract.getWithdrawableAmount(),
          contract.getMemberBonusInfo(await signer.getAddress())
        ]);

        this.totalRewards = ethers.utils.formatUnits(totalBonuses, 6);
        this.staticBonus = ethers.utils.formatUnits(cumulativeStaticBonus, 6);
        this.dynamicBonus = ethers.utils.formatUnits(cumulativeDynamicBonus, 6);
        this.withdrawableDividends = ethers.utils.formatUnits(withdrawableAmount, 6);

      } catch (error) {
        console.error("查询奖金失败", error);
        alert("查询失败，请确保已连接到MetaMask并检查网络。");
      }
    },
    async getUserDividendsPerRound() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      try {
        const address = await signer.getAddress();
        const dailyBonuses = await contract.getDailyDynamicBonus(address);
        this.dividendsPerRound = dailyBonuses;
      } catch (error) {
        console.error("获取分红明细失败", error);
        alert("获取分红明细失败，请检查控制台。");
      }
    },
    async withdrawDividends() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      try {
        const amountInWei = ethers.utils.parseUnits(this.amountToWithdraw.toString(), 6);
        const withdrawTx = await contract.withdraw(amountInWei);
        await withdrawTx.wait();

        alert("提取成功！");
        this.getUserBonuses();
      } catch (error) {
        console.error("提取失败", error);
        alert("提取失败，错误详情见控制台。");
      }
    },
    claimFC() {
      this.$router.push('/claim-fc');
    },
    loadMore() {
      this.currentPage += 1;
    },
    formatUSDT(value) {
      return ethers.utils.formatUnits(value, 6);
    },
    formatTimestamp(index) {
      const date = new Date();
      date.setDate(date.getDate() - index);
      return date.toLocaleDateString();
    }
  },
  mounted() {
    this.getUserBonuses();
    this.getUserDividendsPerRound();
  }
};
</script>

<style scoped>
@import "@/assets/css/style.css";
@import "@/assets/css/detill.css";

.dividends-scrollable {
  max-height: 160px;
  overflow-y: auto;
  position: relative;
}

.main-content h3 {
  position: sticky;
  top: 0;
  background-color: red;
  z-index: 1;
  
}
.main-content {
    text-align: center;
    height: 510px;
  }

.main {
  padding-top: 30px;
  
}

.load-more-button {
  display: block;
  margin: 10px auto;
  padding: 5px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
} 

.load-more-button:hover {
  background-color: #0056b3;
} 

.claim-fc-button {
  display: block;
  margin: 10px auto;
  padding: 5px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.claim-fc-button:hover {
  background-color: #218838;
}
 </style> 