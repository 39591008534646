import { render, staticRenderFns } from "./copy-invite.vue?vue&type=template&id=688bad5d&scoped=true"
import script from "./copy-invite.vue?vue&type=script&lang=js"
export * from "./copy-invite.vue?vue&type=script&lang=js"
import style0 from "./copy-invite.vue?vue&type=style&index=0&id=688bad5d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "688bad5d",
  null
  
)

export default component.exports