<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <p>我的资产</p>
      </div>
      <div class="main">
        <button @click="goback" class="return">
          <img src="@/assets/images/left-arrow.png" alt="返回">
        </button>
        <div class="main-content">
          <div class="membership-area">
            <div class="membership-content">
              <!-- 根据会员状态显示激活FC币账户按钮 -->
              <button v-if="memberStatus === 'VIP会员'" @click="activateMembership" class="activate-button">激活FC币账户</button><br>
              <p id="membershipStatus">领取FC币状态：{{ membershipStatus }}</p>
              <p>每天可领取0.5枚FC币</p>
              <p id="claimCount">已投放FC币次数：{{ claimCount }}</p>
              <!-- 根据会员状态显示可领取代币余额和领取代币按钮 -->
              <!-- <p id="claimableBalance">可领取FC余额：{{ claimableBalance }} FC</p> -->
              <p id="claimableBalance" style="color: red;">可领取FC余额：{{ claimableBalance }} FC</p>
              <button v-if="memberStatus === 'VIP会员'" @click="claimTokens" class="claim-button">领取代币</button>
              <!-- 始终显示会员状态和已投放FC币次数 -->
              <!-- <p id="membershipStatus">领取FC币状态：{{ membershipStatus }}</p>
              <p id="claimCount">已投放FC币次数：{{ claimCount }}</p> -->
            </div>
          </div>
          <div class="member-status-area">
            <div class="member-status-content">
              <p id="memberStatus">会员状态：{{ memberStatus }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddresss, contractABII, usdtTokenAddress, usdtAbi } from '@/js/contractsss.js';
import { contractAddress, contractABI } from '@/js/contract.js';

export default {
  name: 'income',
  data() {
    return {
      membershipStatus: '加载中...',
      claimableBalance: '加载中...',
      claimCount: '加载中...',
      memberStatus: '加载中...',
      usdtBalance: '加载中...'
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    async connectWallet() {
      if (!window.ethereum) {
        console.error("未检测到Ethereum提供商。请安装MetaMask。");
        return null;
      }
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        return new ethers.providers.Web3Provider(window.ethereum);
      } catch (error) {
        console.error("用户拒绝了账户访问请求", error);
        return null;
      }
    },
    async activateMembership() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddresss, contractABII, signer); // 使用 contractAddresss 和 contractABII

      try {
        const activateTx = await contract.applyForMembership();
        await activateTx.wait();

        alert("会员激活成功！");
        await this.getMembershipStatus();
        await this.getClaimableBalance();
      } catch (error) {
        console.error("会员激活失败", error);
        alert("会员激活失败，错误详情见控制台。");
      }
    },
    async getMembershipStatus() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddresss, contractABII, signer); // 使用 contractAddresss 和 contractABII

      try {
        const address = await signer.getAddress();
        const status = await contract.checkMyMembershipStatus({ from: address });
        this.membershipStatus = status;
      } catch (error) {
        console.error("查询会员状态失败", error);
        alert("查询会员状态失败，错误详情见控制台。");
      }
    },
    async getClaimableBalance() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddresss, contractABII, signer); // 使用 contractAddresss 和 contractABII

      try {
        const address = await signer.getAddress();
        const balance = await contract.checkClaimableBalance({ from: address });
        console.log("Raw claimable balance:", balance); // 调试信息
        this.claimableBalance = parseFloat(ethers.utils.formatUnits(balance, 18)).toFixed(2); // 修改这里
      } catch (error) {
        console.error("查询可领取代币余额失败", error);
        alert("查询可领取代币余额失败，错误详情见控制台。");
      }
    },
    async claimTokens() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddresss, contractABII, signer); // 使用 contractAddresss 和 contractABII

      try {
        const claimTx = await contract.claimTokens();
        await claimTx.wait();

        alert("代币提取成功！");
        await this.getClaimableBalance();
        await this.getUsdtBalance();
        await this.getClaimCount();
      } catch (error) {
        console.error("代币提取失败", error);
        alert("代币提取失败，错误详情见控制台。");
      }
    },
    async getUsdtBalance() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const usdtContract = new ethers.Contract(usdtTokenAddress, usdtAbi, signer); // 使用 usdtTokenAddress 和 usdtAbi

      try {
        const address = await signer.getAddress();
        const balance = await usdtContract.balanceOf(address);
        console.log("Raw USDT balance:", balance); // 调试信息
        this.usdtBalance = parseFloat(ethers.utils.formatUnits(balance, 18)).toFixed(6); // 修改这里
      } catch (error) {
        console.error("查询USDT余额失败", error);
        alert("查询USDT余额失败，错误详情见控制台。");
      }
    },
    async checkClaimCount() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddresss, contractABII, signer); // 使用 contractAddresss 和 contractABII

      try {
        const address = await signer.getAddress();
        const count = await contract.checkClaimCount({ from: address });
        console.log("已提取代币次数:", count); // 调试信息
        this.claimCount = count.toNumber();
      } catch (error) {
        console.error("查询已提取代币次数失败", error);
        alert("查询已提取代币次数失败，错误详情见控制台。");
      }
    },
    async getClaimCount() {
      await this.checkClaimCount();
    },
    async getMemberDetail() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer); // 使用 contractAddress 和 contractABI

      try {
        const address = await signer.getAddress();
        const status = await contract.getMemberStatus(address);
        console.log("会员状态:", status); // 调试信息
        this.memberStatus = status;
      } catch (error) {
        console.error("查询会员状态失败", error);
        alert("查询会员状态失败，错误详情见控制台。");
      }
    }
  },
  mounted() {
    // 初始化加载时获取会员状态、领取FC币状态、可领取代币余额、已投放FC币次数和USDT余额
    this.getMemberDetail();
    this.getMembershipStatus();
    this.getClaimableBalance();
    this.getUsdtBalance();
    this.getClaimCount();
  }
};
</script>

<style scoped>
@import "@/assets/css/style.css";
@import "@/assets/css/detill.css";

.main {
  padding-top: 30px;
}

.main-content {
  text-align: center;
  height: 500px;
}

.activate-button, .claim-button, .member-status-button, .claim-count-button, .withdraw-button, .load-more-button {
  display: block;
  margin: 10px auto;
  padding: 5px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.activate-button:hover, .claim-button:hover, .member-status-button:hover, .claim-count-button:hover, .withdraw-button:hover, .load-more-button:hover {
  background-color: #218838;
}

.member-status-area {
  margin-top: 20px;
}

.member-status-content {
  text-align: center;
}
</style>