<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <button class="return" @click="goBack">
          <img src="@/assets/images/left-arrow.png" alt="返回" />
        </button>
        <p>FC币结算管理页面</p>
      </div>
      <br><br><br>
      <div class="main">
        <div class="wallet-section">
          <!-- <p>钱包余额: {{ walletBalance }} ETH</p> -->
          <p>FC余额: {{ usdtBalance }} FC</p>
        </div>
        <div v-if="isOwner" class="owner-section">
          <div class="input-group">
            <label for="tokenAmount">输入代币数量:</label>
            <input type="number" id="tokenAmount" v-model="tokenAmount" step="0.01" />
            <button @click="depositTokens" class="button">充值</button>
            <button @click="withdrawTokens" class="button">提取</button>
          </div>
          <div class="input-group">
            <label for="claimableAmount">每次授权代币数量:</label>
            <input type="number" id="claimableAmount" v-model="claimableAmount" step="0.01" />
            <button @click="setClaimableTokensPerAuthorization" class="button">设置</button>
          </div>
          <div class="balance-section">
            <p>合约内代币余额: {{ tokenBalance }} FC币</p>
          </div>
          <div class="member-count-section">
            <p>领取代币的会员数量: {{ claimedMembersCount }}</p>
            <p>活跃状态的会员数量: {{ activeMembersCount }}</p>
          </div>
          <div class="authorize-section">
            <button @click="authorizeClaimableTokensToAllActiveMembers" class="button">授权所有正式会员</button><br><br>
            <button @click="withdrawAllTokens" class="button">提取所有代币</button>
          </div>
        </div>
        <div v-else class="non-owner-section">
          <p>只有合约部署者可以进行充值和查看余额操作</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddress, contractABI, usdtTokenAddress, usdtAbi } from '@/js/contractss.js';



export default {
  name: 'delivery-addresses',
  data() {
    return {
      account: null,
      contract: null,
      usdtContract: null,
      tokenAmount: 0,
      claimableAmount: 0,
      tokenBalance: 0,
      usdtBalance: 0,
      isOwner: false,
      walletBalance: 0, // 在这里定义 walletBalance 属性
      claimedMembersCount: 0, // 新添加的领取代币的会员数量
      activeMembersCount: 0 // 新添加的活跃状态的会员数量
    };
  },
  methods: {
    async goBack() {
      this.$router.push('/settings');
    },
    async initWeb3() {
      if (window.ethereum) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          this.account = await signer.getAddress();

          // 初始化合约
          this.contract = new ethers.Contract(contractAddress, contractABI, signer);
          this.usdtContract = new ethers.Contract(usdtTokenAddress, usdtAbi, signer);

          // 获取钱包余额
          this.walletBalance = await this.getWalletBalance();
          this.usdtBalance = await this.getUsdtBalance();

          // 检查当前账户是否为合约部署者
          const owner = await this.contract.getOwner();
          this.isOwner = this.account.toLowerCase() === owner.toLowerCase();

          if (this.isOwner) {
            await this.fetchTokenBalance();
            await this.fetchClaimedMembersCount();
            await this.fetchActiveMembersCount();
          } else {
            alert("只有合约部署者可以查看所有收货信息");
          }
        } catch (error) {
          console.error("Error fetching account or owner:", error);
          alert("无法获取账户信息或合约拥有者，请检查合约地址或ABI配置是否正确");
        }
      } else {
        alert("请安装 MetaMask!");
      }
    },
    async getWalletBalance() {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const balance = await provider.getBalance(this.account);
        return ethers.utils.formatEther(balance);
      } catch (error) {
        console.error("Error fetching wallet balance:", error);
        return 0;
      }
    },
    async getUsdtBalance() {
      try {
        const balance = await this.usdtContract.balanceOf(this.account);
        return parseFloat(ethers.utils.formatUnits(balance, 18)).toFixed(2); // 确保 USDT 余额显示为浮点数并保留2位小数
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
        return 0;
      }
    },
    async fetchTokenBalance() {
      try {
        const balance = await this.contract.getTokenBalance();
        this.tokenBalance = parseFloat(ethers.utils.formatUnits(balance, 18)).toFixed(2); // 确保合约内余额显示为浮点数并保留2位小数
      } catch (error) {
        console.error("Error fetching token balance:", error);
        alert("无法获取代币余额，请重试");
      }
    },
    async fetchClaimedMembersCount() {
      try {
        const count = await this.contract.getClaimedMembersCount();
        this.claimedMembersCount = count.toNumber();
      } catch (error) {
        console.error("Error fetching claimed members count:", error);
        alert("无法获取领取代币的会员数量，请重试");
      }
    },
    async fetchActiveMembersCount() {
      try {
        const count = await this.contract.getActiveMembersCount();
        this.activeMembersCount = count.toNumber();
      } catch (error) {
        console.error("Error fetching active members count:", error);
        alert("无法获取活跃状态的会员数量，请重试");
      }
    },
    async depositTokens() {
      if (this.tokenAmount <= 0) {
        alert("请输入有效的代币数量");
        return;
      }

      try {
        // 首先批准合约使用代币
        const approveTx = await this.usdtContract.approve(this.contract.address, ethers.utils.parseUnits(this.tokenAmount.toString(), 18));
        await approveTx.wait();

        // 然后调用合约的充值方法
        const depositTx = await this.contract.depositTokens(ethers.utils.parseUnits(this.tokenAmount.toString(), 18), {
          gasLimit: 300000
        });

        await depositTx.wait();
        alert("充值成功");
        await this.fetchTokenBalance();
        this.usdtBalance = await this.getUsdtBalance();
      } catch (error) {
        console.error("Error depositing tokens:", error);
        alert("充值失败，请检查网络或代币数量");
      }
    },
    async setClaimableTokensPerAuthorization() {
      if (this.claimableAmount <= 0) {
        alert("请输入有效的每次授权代币数量");
        return;
      }

      try {
        const tx = await this.contract.setClaimableTokensPerAuthorization(ethers.utils.parseUnits(this.claimableAmount.toString(), 18), {
          gasLimit: 300000
        });

        await tx.wait();
        alert("设置每次授权代币数量成功");
      } catch (error) {
        console.error("Error setting claimable tokens per authorization:", error);
        alert("设置失败，请检查网络或代币数量");
      }
    },
    async authorizeClaimableTokensToAllActiveMembers() {
      try {
        const tx = await this.contract.authorizeClaimableTokensToAllActiveMembers({
          gasLimit: 300000
        });

        await tx.wait();
        alert("授权所有正式会员成功");
        await this.fetchTokenBalance();
      } catch (error) {
        console.error("Error authorizing claimable tokens to all active members:", error);
        alert("授权失败，请检查网络或代币数量");
      }
    },
    async withdrawTokens() {
      if (this.tokenAmount <= 0) {
        alert("请输入有效的代币数量");
        return;
      }

      try {
        const tx = await this.contract.withdrawTokens(ethers.utils.parseUnits(this.tokenAmount.toString(), 18), {
          gasLimit: 300000
        });

        await tx.wait();
        alert("提取成功");
        await this.fetchTokenBalance();
        this.usdtBalance = await this.getUsdtBalance();
      } catch (error) {
        console.error("Error withdrawing tokens:", error);
        alert("提取失败，请检查网络或代币数量");
      }
    },
    async withdrawAllTokens() {
      try {
        const tx = await this.contract.withdrawAllTokens({
          gasLimit: 300000
        });

        await tx.wait();
        alert("提取所有代币成功");
        await this.fetchTokenBalance();
        this.usdtBalance = await this.getUsdtBalance();
      } catch (error) {
        console.error("Error withdrawing all tokens:", error);
        alert("提取所有代币失败，请检查网络或代币数量");
      }
    }
  },
  async mounted() {
    console.log("Component mounted");
    await this.initWeb3();
  }
};
</script>

<style scoped>
@import "@/assets/css/style.css";

.top button {
  margin-right: 10px;
}

.return {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.wallet-section {
  margin-top: 10px;
  text-align: center;
  color: #007bff;
}

.owner-section {
  margin-top: 20px;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-group label {
  margin-right: 10px;
}

.input-group input {
  width: 100px;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.balance-section {
  margin-top: 10px;
}

.member-count-section {
  margin-top: 10px;
  text-align: center;
  color: #007bff;
}

.non-owner-section {
  margin-top: 20px;
  text-align: center;
  color: red;
}

.authorize-section {
  margin-top: 20px;
}

.authorize-section button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.authorize-section button:hover {
  background-color: #218838;
}
</style>